import React from 'react';
import logo from './logo.png';
import './App.css';
import EmailLink from './emaillink.js';
import Footer from './footer.js';

function App() {
  return (
    <div className="App">
    
      <header className="App-header">
        <h1 className="Big">Welcome to...</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <h1 className="Big">The Sticks</h1>
      </header>

      <h2>The Boys</h2>
      <div className="Grid">
        <a href="/aaron.js" className="Card">
          <img src="aaron.jpg" alt="Aaron" style={{ width: '100%' }} />
          <h1>Aaron Cantu</h1>
          <h3>Houston, TX, USA</h3>
        </a>
    
        <a href="/src/john" className="Card">
          <img src="john.jpg" alt="John" style={{ width: '100%' }} />
          <h1>John Carpenter</h1>
          <h3>Burlington, KY, USA</h3>
        </a>
    
        <a href="/src/val.js" className="Card">
          <img src="val.jpg" alt="Valerius" style={{ width: '100%' }} />
          <h1>Jon Valerius</h1>
          <h3>, MI, USA</h3>
        </a>

        <a href="/blake" className="Card">
          <img src="blake.jpg" alt="Blake" style={{ width: '100%' }} />
          <h1>Blake Taylor</h1>
          <h3>Cincinnati, OH, USA</h3>
        </a>
  
        <a href="/vik" className="Card">
          <img src="vik.jpg" alt="Vik" style={{ width: '100%' }} />
          <h1>Vik Gardella</h1>
          <h3>Lexington, KY, USA</h3>
        </a>
    
      </div>

    
      <h2>The Hits</h2>
      <div className="Stack">

        <div className="GridTwo">
          <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="440" width="100%" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/beat-toven-single/1755387402?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark"></iframe>
        </div>

        <div className="GridTwo">
          <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="100%" width="100%" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/abducted-the-5th-encounter/1750696213?i=1750696215?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark"></iframe>
        </div>
  
        <div className="GridTwo">
          <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="100%" width="100%" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/the-ides-he-marched/1734478505?i=1734478511?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark"></iframe>
        </div>
    
        <div className="GridTwo">
          <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="100%" width="100%" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/this-falling-knife/1730317150?i=1730317347?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark"></iframe>
        </div>
  
        <div className="GridTwo">
          <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="100%" width="100%" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/the-upside-down/1720677226?i=1720677505?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark"></iframe>
        </div>
    
        <div className="GridTwo">
          <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="440" width="100%" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/guitar-anti-hero-ep/1714098247?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark"></iframe>
        </div>

        <div className="GridTwo">
          <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="440" width="100%" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/cosmic-conundrum/1689392501?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark"></iframe>
        </div>

        <div className="GridTwo">
          <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="440" width="100%" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/laverne/1661069284?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark"></iframe>
        </div>

        <div className="GridTwo">
          <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="100%" width="100%" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/abducted-the-4th-encounter/1712855801?i=1712855802?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark"></iframe>
        </div>
    
        <div className="GridTwo">
          <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="100%" width="100%" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/violet-intentions/1709910004?i=1709910005?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark"></iframe>
        </div>
    
        <div className="GridTwo">
          <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="100%" width="100%" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/hollowed-ground/1693212119?i=1693212123?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark"></iframe>
        </div>
    
        <div className="GridTwo">
          <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="100%" width="100%" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/raptured/1690157670?i=1690157672?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark"></iframe>
        </div>
    
      </div>
    
    
      <h2>The Roots</h2>
      <div className="GridThree">
        <iframe width="100%"
              height="480"
              frameBorder="0"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCZxnYUrVOjqMpvGds1_xyhaaCulV8gma0
                  &q=Queen+City+Square,Cincinnati+OH">
        </iframe>
      </div>


      <h2>The Blurb</h2>
      <div className="GridFour">
        <EmailLink imgSrc="./facebook.png" imgDest="https://www.facebook.com/profile.php?id=100088689909455"/>
        <EmailLink imgSrc="./twitter.png" imgDest="www.github.com"/>
        <EmailLink imgSrc="./youtube.png" imgDest="https://www.youtube.com/@VikAndTheSticks"/>
        <EmailLink imgSrc="./snapchat.png" imgDest="www.github.com"/>
        <EmailLink imgSrc="./instagram.png" imgDest="www.github.com"/>
      </div>
                
      <Footer />

    </div>

  );
}

export default App;
