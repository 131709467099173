import React from 'react';

const EmailLink = ({imgSrc, imgDest}) => {
  return (
    <a href={imgDest}>
      <img src={imgSrc} alt="Social Link" style={{ width: '100px', height: '100px' }}/>
    </a>
  );
};

export default EmailLink;
