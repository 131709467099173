import './App.css';


export default function Footer() {
  return(
    <>
      <div className="footer">
          <h1>Tower One Records &copy; 2024</h1>
      </div>
    </>
  );
}
